.intro {
  p {
    font-size: 19px;
    letter-spacing: 0;
    line-height: 1.8;
  }
  .team-photos img {
    max-height: 250px;
  }
}
