/* The following block can be included in a custom.scss */

/* make the customizations */
$theme-colors: (
  "info": tomato,
  "danger": teal,
);

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";
